import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

export default function sessakuSlider2(){
    const settings = {
        dots:true,
        arrows:true,
    }
    return(
        <div>
          <div className="slider_wrap">
        
            <Slider {...settings} className="slider">
              <li className="slider-contents slide_1 bg-img_cover black-filter">
                <div className="contents_wrap">
                  <div className="product-title">
                    <div>PRODUCT 2</div><div>月額5,000円から利用可能</div>
                  </div>{/*.product-title*/}
                  <h2 className="product-h2 underline">設備稼働<br />監視分析システム</h2><div />
                  <div className="product-lead">
                    「旋盤」・「マシニングセンタ」・「研削加工機」といった設備の稼働状態のリアルタイム監視および分析を可能にする、圧倒的低コストで導入可能な稼働監視分析システム。
                  </div>{/*.product-lead*/}
                </div>{/*.contents_wrap*/}
              </li>{/*.slider-contents*/}
        
              <li className="slider-contents slide_2">
                <div className="bk-back"><div className="system-img bg-img" /></div>
                <div className="contents-box">
                  <div className="contents-title">SYSTEM</div>
                  <h3 className="underline">製品概要</h3><div />
                  <div className="contents-comment">
                    本システムは、定番のアンドンやガントチャートなど、リアルタイムでの稼働監視機能はもちろん、設備毎の稼働率を時系列で分析でき、特に稼働率の低い設備
を「見える化」する、比較分析機能などを標準で搭載しています。<br />
                    さらにソフトウェアをアップデートすることで、最新の豊富な機能が永続的にご利用いただけます(現在準備中)。
                  </div>
                </div>{/*.contents-box*/}
              </li>{/*.slider-contents*/}
        
              <li className="slider-contents slide_3">
                <div className="contents-box">
                  <div className="contents-title">FUTURE</div>
                  <h3 className="underline">本製品で繋がる未来</h3><div />
                  <div className="contents-comment">
                    <ul className="future-list">
                      <li className="future_1">
                        <div className="check bg-img" />
                        <h4 className="list-lead">圧倒的低コストで改善</h4>
                        <div className="list-text">
                          月額5,000円でリアルタイム稼働監視機能や稼働率の分析機能などが全て使用でき、低コストで稼働状態の把握へ繋がります。
                        </div>
                      </li>
                      <li className="future_2">
                        <div className="check bg-img" />
                        <h4 className="list-lead">旧式でも収集分析可</h4>
                        <div className="list-text">
                          クランプ式電流センサを設置することで、旧式設備でも簡単に稼働状態の収集分析ができる環境へ繋がります。
                        </div>
                      </li>
                      <li className="future_3">
                        <div className="check bg-img" />
                        <h4 className="list-lead">工具の徹底監視分析</h4>
                        <div className="list-text">
                          工具の摩耗度推定機能やチッピング/折れの検出機能を追加でき、工具利用状況の徹底監視分析に繋がります。
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>{/*.contents-box*/}
                <div className="d-none d-md-block">
                    <div className="future-img bg-img_cover" />
                </div>
              </li>{/*.slider-contents*/}
        
            </Slider>
        
          </div>{/*slider_wrap*/}
        
        <div className="slide-controller">
              <div className="gauge">
                <div className="gauge_0 gauge_current"><div /></div>
                <div className="gauge_1"><div /></div>
                <div className="gauge_2"><div /></div>
              </div>
            </div>{/*.slide-controller*/}
            <div className="slide-title">PRODUCT 2</div>
        </div>
    )
}