import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

export default function sessakuSlider1(){
    const settings = {
        dots:true,
        arrows:true,
    }
    return(
        <div>
            <div className="slider_wrap">
                <Slider {...settings} className="slider">
        
                  <li className="slider-contents slide_1 bg-img_cover black-filter">
                    <div className="contents_wrap">
                      <div className="product-title">
                        <div>PRODUCT 1</div><div>特許出願中・トライアル無料</div>
                      </div>{/*.product-title*/}
                      <h2 className="product-h2 underline">AIを用いた切削工具<br />状態管理システム</h2><div />
                      <div className="product-lead">
                        「工具交換のコスト削減」・「不良品防止を図る工具の折れやチッピングの自動検知」・「工具の利用状況を一元管理・分析」など、旋盤・マシニングセンタにかかる課題改善を図るシステム。
                      </div>{/*.product-lead*/}
                    </div>{/*.contents_wrap*/}
                  </li>{/*.slider-contents*/}
        
                  <li className="slider-contents slide_2">
                    <div className="bk-back"><div className="system-img bg-img" /></div>
                    <div className="contents-box">
                      <div className="contents-title">SYSTEM</div>
                      <h3 className="underline">製品概要</h3><div />
                      <div className="contents-comment">
                        本システムは、主軸モーター・ATCなど、工具切替モーターの2箇所に設置するクランプからの電流データと、オペレータが入力する工具交換履歴データをAIに学習させることで、摩耗度予測や異常検知を行います。<br />
                        また、同一ネットワーク上のPCなら、どこからでも工具一括監視分析画面が参照でき、子機側では設備個別の状態の参照と工具交換履歴登録が可能です。
                      </div>
                    </div>{/*.contents-box*/}
                  </li>{/*.slider-contents*/}
        
                  <li className="slider-contents slide_3">
                    <div className="contents-box">
                      <div className="contents-title">FUTURE</div>
                      <h3 className="underline">本製品で繋がる未来</h3><div />
                      <div className="contents-comment">
                        <ul className="future-list">
                          <li className="future_1">
                            <div className="check bg-img" />
                            <h4 className="list-lead">工具費用の削減</h4>
                            <div className="list-text">
                              工具負荷データから工具寿命を自動推定することで、工具交換のタイミングを最適化でき、工具費用の削減に繋がります。
                            </div>
                          </li>
                          <li className="future_2">
                            <div className="check bg-img" />
                            <h4 className="list-lead">不良品の防止</h4>
                            <div className="list-text">
                              工具負荷データを監視して異常検知することで、折れ/チッピングを自動検知して対処でき、不良品の防止に繋がります。
                            </div>
                          </li>
                          <li className="future_3">
                            <div className="check bg-img" />
                            <h4 className="list-lead">工具寿命の強化</h4>
                            <div className="list-text">
                              工具の交換履歴から交換頻度や寿命を分析可能にすることで、工具の使用状況を把握でき、工具寿命の強化に繋がります。
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>{/*.contents-box*/}
                    <div className="d-none d-md-block">
                        <div className="future-img bg-img_cover" />
                    </div>
                  </li>{/*.slider-contents*/}
        
                </Slider>
        
            </div>{/*.slider_wrap*/}
            <div className="slide-controller">
              <div className="gauge">
                <div className="gauge_0 gauge_current"><div /></div>
                <div className="gauge_1"><div /></div>
                <div className="gauge_2"><div /></div>
              </div>
            </div>{/*.slide-controller*/}
        
            <div className="slide-title">PRODUCT 1</div>
        </div>
    )
}