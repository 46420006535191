import React from "react"
import {Link} from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Slider1 from "../components/slider_sessaku-1"
import Slider2 from "../components/slider_sessaku-2"
import Slider3 from "../components/slider_sessaku-3"
import SliderWork from "../components/slider_work"
import Seo from "../components/seo"


const replaceValidation = e =>{
    let val = e.target.value
    //全角を半角に修正
    function han2Zen(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }
    let replaceVal = han2Zen(val);
    e.target.value = replaceVal
    //バリデーション
    Validation(e)
}

let validFlug = [0,0,0,0,0,0,0]
const Validation = e =>{
    let validationMessage = e.target.validationMessage
    let formName = e.target.name
    
    var i
    if(formName==="company"){
        i = 0
    }else if(formName==="department"){
        i = 1
    }else if(formName==="occupation"){
        i = 2
    }else if(formName==="position"){
        i = 3
    }else if(formName==="name"){
        i = 4
    }else if(formName==="mail"){
        i = 5
    }else if(formName==="tel"){
        i = 6
    }else if(formName==="text"){
        i = 7
    }
    if(validationMessage === ""){
        e.target.style.background="whitesmoke"
        validFlug[i] = 1
    }else{
        e.target.style.background="pink"
        validFlug[i] = 0
    }
    //送信ボタン
    let submitFlug = [1,1,1,1,1,1,1]
    let submit = document.getElementById("submit")
    if(validFlug.toString() === submitFlug.toString()){
        submit.style.filter="none"
        submit.style.pointerEvents="auto"
    }else{
        submit.style.filter="grayscale(100%)"
        submit.style.pointerEvents="none"
    }
}
 

const sessakuHtml = ({data,location}) => (
	<Layout>
	  <Seo
		pagetitle="切削強化"
		pagedesc="切削加工業向けIoTパッケージのご紹介"
		pagepath={location.pathname}
	  />
    
	<Helmet>
        
        <body id="body" />
	</Helmet>
<div className="container-fluid sessaku" id="sessaku_wrap">
  <div className="row">
    <div className="fullpage">
      <div className="section top-view">
        <div className="top-img bg-img_cover" />
        <ul className="breadcrumb">
          <li><Link to={`/`}>TOP</Link></li>
          <li><Link to={`/product/`}>PRODUCT</Link></li>
          <li>切削強化</li>
        </ul>{/*.breadcrumb*/}
        <div className="h1_wrap">
          <h1>切削強化</h1>
        </div>{/*.h1_wrap*/}
        <div className="h1-lead">切削加工業向けIoTパッケージ</div>
        <a href="#scroll" id="scroll-button">
          <div className="scroll-down">
            <div>SCROLL</div><div />
          </div>
        </a>
      </div>{/*.top-view*/}
      <div id="scroll" className="section product product_1 full-slider">
        <Slider1 />
      </div>{/*.product_1*/}
      <div className="section product product_2 full-slider">
        <Slider2 />
      </div>{/*.product_2*/}
      <div className="section product product_3 full-slider">
        <Slider3 />
      </div>{/*.product_3*/}
      <div className="section">
        <div className="flow">
          <div className="contents-title">FLOW</div>
          <div className="contents-box">
            <h3 className="underline">製品導入の流れ</h3><div />
            <div className="contents-comment">
              MAZINでは、製造現場のことをきちんと理解している担当者が、お客様の課題を的確に把握した上で最適な製品をワンストップでご提供させていただきます。<br />
              ただ「見える化」するだけではなく、常に現場と伴走し根本的な課題改善へ繋げていく。MAZINはそんな独自の製品・サービスをお客様へお届けいたします。
            </div>
            <ul className="flow-list">
              <li><div>一</div><div>お問い合わせ</div></li>
              <li><div>二</div><div>ご説明</div></li>
              <li><div>三</div><div>無料トライアル</div></li>
              <li><div>四</div><div>ご発注</div></li>
              <li><div>五</div><div>導入</div></li>
              <li><div>六</div><div>ご請求</div></li>
              <li><div>七</div><div>サポート</div></li>
            </ul>
          </div>{/*.contents-box*/}
        </div>{/*.flow*/}
        <div className="work wide-slider">
          <div className="contents-title">WORK</div>
          <div className="contents-box">
              <SliderWork />
          </div>{/*.contents-box*/}
        </div>{/*.work*/}
        <div className="download">
          <div className="contents-title">DOWNLOAD</div>
          <div className="contents-box">
            <h3 className="underline">製品カタログはこちら</h3><div />
            <div className="contents-comment">
              費用や詳細についてご興味のある方は、下記必要事項をご記入の上、こちらから製品カタログのダウンロードをお願いいたします。<br />
              ご相談・お問い合せは、コンタクトフォームよりどうぞお気軽にご連絡ください。
              <div className="small">＊入力は全て必須項目となっております</div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5 col-xl-6"><div className="dl-img bg-img" /></div>
              <div className="col-12 col-lg-7 col-xl-6">
                <form name="SESSAKU-DL" method="POST" data-netlify="true" action={`/sessaku_dl/`}>
                    <table>
                      <tbody>
                        <input type="hidden" name="form-name" value="SESSAKU-DL" />
                        <tr><th>会社名</th>
                            <td>
                                <input type="text" name="company" required
                                    placeholder="ZZZ株式会社"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>部署名</th>
                            <td>
                                <input type="text" name="department" required
                                    placeholder="ZZZ部"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>職種</th>
                            <td>
                                <input type="text" name="occupation" required
                                    placeholder="ZZZ" 
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>役職</th>
                            <td>
                                <input type="text" name="position" required
                                    placeholder="ZZZ"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>氏名</th>
                            <td>
                                <input type="text" name="name" required
                                    placeholder="ZZ ZZZ"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th className="line-2" aria-label="勤務先メールアドレス"><div><span>勤務先</span><span>メールアドレス</span></div></th>
                            <td>
                                <input type="email" name="mail" required
                                    placeholder="ZZZ@mazin.tech" 
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>電話番号(携帯)</th>
                            <td>
                                <input type="tel" name="tel" required
                                    placeholder="00-0000-0000"
                                    onBlur={replaceValidation}
                                    onInput={replaceValidation}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>IoT導入予定時期</th>
                            <td>
                                <select name="timing" required>
                                  <option value="3ヶ月以内">3ヶ月以内</option>
                                  <option value="半年以内">半年以内</option>
                                  <option value="1年以内">1年以内</option>
                                  <option value="1年以上先">1年以上先</option>
                                  <option value="時期は未定だが、検討中">時期は未定だが、検討中</option>
                                  <option value="予定はない">予定はない</option>
                                </select>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="submit_wrap">
                        <button type="submit" id="submit">ダウンロード</button>
                        <div className="small">このサイトはreCAPTCHAによって保護されており、<br />Googleのプライバシーポリシーと利用規約が適用されます。</div>
                    </div>
                </form>
              </div>
            </div>
          </div>{/*.contents-box*/}
        </div>{/*.download*/}
      </div>{/*.section*/}
    </div>{/*.fullpage*/}
  </div>{/*.row*/}
</div>{/*.container-fluid*/}



	
	</Layout>

)

export default sessakuHtml