import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

export default function sessakuSlider3(){
    const settings = {
        dots:true,
        arrows:true,
    }
    return(
        <div>
          <div className="slider_wrap">
        
            <Slider {...settings} className="slider">
              <li className="slider-contents slide_1 bg-img_cover black-filter">
                <div className="contents_wrap">
                  <div className="product-title">
                    <div>PRODUCT 3</div><div>トライアル無料</div>
                  </div>{/*.product-title*/}
                  <h2 className="product-h2 underline">研削砥石ツルーイング<br />回数最適化システム</h2><div />
                  <div className="product-lead">
                    研削加工機の真円度出しにおいて、必要なツルーイング回数が分からず必要以上に時間をかけてしまっている切削事業者様向けの、ツルーイング回数最適化システム。
                  </div>{/*.product-lead*/}
                </div>{/*.contents_wrap*/}
              </li>{/*.slider-contents*/}
        
              <li className="slider-contents slide_2">
                <div className="bk-back"><div className="system-img bg-img" /></div>
                <div className="contents-box">
                  <div className="contents-title">SYSTEM</div>
                  <h3 className="underline">製品概要</h3><div />
                  <div className="contents-comment">
                    本システムは、クランプ式電流センサから電流値を測定し、最適な真円度を導くことで、これまで費やしていた作業時間を削減でき、加工品質の向上を可能にします。<br />
                    また、旧式設備でも簡単に導入できます。
                  </div>
                </div>{/*.contents-box*/}
              </li>{/*.slider-contents*/}
        
              <li className="slider-contents slide_3">
                <div className="contents-box">
                  <div className="contents-title">FUTURE</div>
                  <h3 className="underline">本製品で繋がる未来</h3><div />
                  <div className="contents-comment">
                    <ul className="future-list">
                      <li className="future_1">
                        <div className="check bg-img" />
                        <h4 className="list-lead">作業時間を削減</h4>
                        <div className="list-text">
                          研削砥石の主軸負荷から真円度を自動推定することで、十分に真円度が出たタイミングを把握でき、作業時間削減に繋がります。
                        </div>
                      </li>
                      <li className="future_2">
                        <div className="check bg-img" />
                        <h4 className="list-lead">旧式でも簡単改善</h4>
                        <div className="list-text">
                          クランプ式電流センサを設置することで、旧式設備でも簡単に導入でき、最適な作業環境の改善へ繋がります。
                        </div>
                      </li>
                      <li className="future_3">
                        <div className="check bg-img" />
                        <h4 className="list-lead">加工品質の向上</h4>
                        <div className="list-text">
                          最適な真円度が出たタイミングで作業を行えるので、これまで以上に品質のバラツキを防ぎ、加工品質の向上へ繋がります。
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>{/*.contents-box*/}
                <div className="d-none d-md-block">
                    <div className="future-img bg-img_cover" />
                </div>
              </li>{/*.slider-contents*/}
        
            </Slider>
        
            </div>{/*.slider_wrap*/}
            <div className="slide-controller">
              <div className="gauge">
                <div className="gauge_0 gauge_current"><div /></div>
                <div className="gauge_1"><div /></div>
                <div className="gauge_2"><div /></div>
              </div>
            </div>{/*.slide-controller*/}
            <div className="slide-title">PRODUCT 3</div>
        </div>
    )
}